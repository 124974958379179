import Head from 'next/head'
import React, { FC } from 'react'

import { appName } from '@/config/env'

interface BlankLayoutProps {
  title: string
  description?: string
  children: React.ReactNode
}

const Blank: FC<BlankLayoutProps> = ({ title, description, children }) => {
  return (
    <>
      <Head>
        <title>{`${title} | ${appName}`}</title>

        {description && (
          <meta
            name='description'
            content={description}
          />
        )}
      </Head>

      {children}
    </>
  )
}

export default Blank
