import { NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import React from 'react'

import { wrapper } from '@/store'

import NotFound from './NotFound'

const Page: NextPage = () => {
  return (
    <main className='flex h-screen w-full flex-col items-center justify-center bg-[#1A2238]'>
      <NotFound />
    </main>
  )
}

export const getProps = wrapper.getStaticProps(() => async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(String(locale), ['common', 'error'])),
    },
  }
})

export default Page
